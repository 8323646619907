import { render, staticRenderFns } from "./BaseTable.vue?vue&type=template&id=7821c9b6&scoped=true&"
import script from "./BaseTable.vue?vue&type=script&lang=ts&"
export * from "./BaseTable.vue?vue&type=script&lang=ts&"
import style0 from "./BaseTable.vue?vue&type=style&index=0&id=7821c9b6&prod&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7821c9b6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCol,VDataTable,VIcon,VRow})
